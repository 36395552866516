<template>
  <div class="trademarkMonitoring_result">
    <div style="height: 90px"></div>
    <div class="danTitle">
      <span @click="skip('/')">首页</span>
      >
      <span @click="skip('/PQ')">商标查询</span>
      >
      <span @click="skip('/TM')">商标监控</span>
      >
      <span @click="skip()">监控结果</span>
    </div>

    <div class="monitoring_top clearfix">
      <div class="condition"  v-if="monitorCategory != 2">
        <div class="right">
          <h2 style="font-size: 16px">监控条件</h2>
          <div>
            <span style="margin-right: 10px;" v-if="monitorCategory == 0">
              监控商标: {{this.trademarkName}}
            </span>
            <span v-if="monitorCategory == 1" style="display: flex; align-items: center">
              监控商标:
              <img style="margin-left: 10px; height: 150px; width: auto" :src="queryDataUrl(this.trademarkImageUrl)"/>
            </span>
          </div>
          <div class="selectCountry">
            <span style="width: 130px">国家/地区：</span>

            <el-radio-group v-model="formData.informantType" @change="resultList">
              <el-radio
                v-for="(item,index) in formData.options"
                :key="index"
                :value="item.counName"
                :label="item.counKey"
              >
                {{item.counName}}&nbsp;({{item.trademarkCount ? item.trademarkCount : 0}})
              </el-radio>
            </el-radio-group>
          </div>
        </div>
      </div>

      <el-row class="condition1" v-if="monitorCategory == 2">
        <el-col :span="formData.informantType == 'CN' ? 8 : 12">
          <div class="condition1Item rightLine">
            <h3>监控条件</h3>
            <el-row class="monitorResultItem">
              <el-col style="width: 80px">监控申请人</el-col>
              <el-col style="width: calc( 100% - 100px );margin-left: 20px">{{this.applyPersonName}}</el-col>
            </el-row>
            <el-row class="monitorResultItem">
              <el-col style="width: 80px">国家/地区</el-col>
              <el-col style="width: calc( 100% - 100px ); margin-left: 20px; max-height: 200px; overflow-y: auto; scrollbar-width:thin;">
                <el-row v-for="(item, index) in formData.options">
                  <el-radio v-model="formData.informantType" :value="item.counKey" :label="item.counKey">
                    {{item.counName}} ({{item.trademarkCount ? item.trademarkCount : 0}})
                  </el-radio>
                </el-row>
              </el-col>
            </el-row>
          </div>
        </el-col>
        <el-col :span="formData.informantType == 'CN' ? 8 : 4" :style="[formData.informantType == 'CN' ? {} : {'border':'1px solid transparent'}]">
          <div v-if="formData.informantType == 'CN'" class="condition1Item">
            <h3>本周商标状态</h3>
            <el-row class="monitorTrademarkStatusList">
              <div class="monitorTrademarkStatusItem" v-for="(item, index) in trademarkStatusList">
                <span style="display:inline-block; width: 130px">{{ item.flowName }}</span> {{ item.flowCount }}
              </div>
            </el-row>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="condition1Item" :class="formData.informantType == 'CN' ? 'leftLine' : ''">
            <h3>申请人商标类别分布图</h3>
            <p style="margin-left: 20px; margin-top: 5px; font-size: 12px; color: #666666">注：类别超过7个的统计图可使用鼠标拖拽和使用滚轮放缩</p>
            <div id="myCategoryCharts" style="height: 100%; width: 100%;"/>
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="monitoring_table">
      <!--近似商标监控表格-->
      <vxe-grid
        v-if="monitorCategory != 2"
        header-row-class-name="vxe_grid_header"
        border="inner"
        height="720px"
        :highlight-current-row="true"
        :highlight-hover-row="true"
        :highlight-current-column="true"
        align="center"
        header-align="center"
        ref='xGrid'
        :cell-class-name="cellClassName"
        :row-style="rowStyle"
        v-bind="monitorCategory == 1 ? gridOptionss : gridOptions"
        @clear-filter="filterChange"
        @filter-change="filterChange"
        @sort-change="filterChange"
      >
        <!--工具栏-->
        <template #toolbar_buttons>
          <div class="monitoring_two">
            <img src="../../assets/images/query/xls.png" style="position: absolute;top: 10px;right: 10px; cursor: pointer" @click="exportManyCountryMonitoringDetail"/>
          </div>
        </template>

        <!--商标图样-->
        <template #imageUrl="{ row }">
          <img style="height: 50px; max-width: 50px" :src="queryDataUrl(row.trademarkImageUrl)" v-if="row.trademarkImageUrl != null"/>
          <span v-else>{{row.trademarkName}}</span>
        </template>

<!--        trialNumber-->
        <template #trialNumber="{ row }">
          <div class="toQuery" @click="guanlian(row, 0)">{{row.trialNumber}}</div>
        </template>

        <template #applicant="{ row }">
          <div class="toQuery" @click="guanlian(row.applyPersonName || row.applyPersonNameEn, 3)">{{row.applyPersonName || row.applyPersonNameEn}}</div>
        </template>

        <template #applyNumber="{ row }">
          <div class="toQuery" @click="guanlian(row.applyNumber, 5)">{{row.applyNumber}}</div>
        </template>

        <!--分页-->
        <template #pager>
          <vxe-pager
            border
            background
            :layouts="['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total']"
            :current-page.sync="current"
            :page-size.sync="size"
            :total="total"
            @page-change="handleCurrentChange"
          >
          </vxe-pager>
        </template>

        <!--自定义空数据模板-->
        <template #empty>
          <div>
            {{$t('personal_lang1.noDate')}}
          </div>
        </template>
      </vxe-grid>
      <!--竞争对手监控表格-->
      <vxe-grid
        v-if="monitorCategory == 2"
        header-row-class-name="vxe_grid_header"
        border="inner"
        height="720px"
        :highlight-current-row="true"
        :highlight-hover-row="true"
        :highlight-current-column="true"
        align="center"
        header-align="center"
        ref='xGrid2'
        :cell-class-name="cellClassName"
        :row-style="rowStyle"
        v-bind="gridOptions2"
        @clear-filter="filterChange"
        @filter-change="filterChange"
        @sort-change="filterChange"
      >
        <!--工具栏-->
        <template #toolbar_buttons>
          <div class="monitoring_two">
            <img src="../../assets/images/query/xls.png" style="position: absolute;top: 10px;right: 10px; cursor: pointer" @click="exportManyCountryMonitoringDetail"/>
          </div>
        </template>

        <!--商标图样-->
        <template #imageUrl="{ row }">
          <img style="height: 50px; max-width: 50px" :src="queryDataUrl(row.trademarkImageUrl)"/>
        </template>

        <template #applicant="{ row }">
          <div class="toQuery" @click="guanlian(row.applyPersonName, 3)">{{row.applyPersonName}}</div>
        </template>

        <template #applyNumber="{ row }">
          <div class="toQuery" @click="guanlian(row.applyNumber, 5)">{{row.applyNumber}}</div>
        </template>

        <template #trademarkStatus="{ row }">
          <div>{{row.businessName == null ? ( row.linkName == null ? "" : row.linkName) : (row.linkName == null ? row.businessName : row.businessName+'-'+row.linkName)}}</div>
        </template>


        <!--分页-->
        <template #pager>
          <vxe-pager
            border
            background
            :layouts="['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total']"
            :current-page.sync="current"
            :page-size.sync="size"
            :total="total"
            @page-change="handleCurrentChange"
          >
          </vxe-pager>
        </template>

        <!--自定义空数据模板-->
        <template #empty>
          <div>
            {{$t('personal_lang1.noDate')}}
          </div>
        </template>
      </vxe-grid>
    </div>
  </div>
</template>

<script>
  import * as echarts from 'echarts'

  export default {
    data() {
      return {
        //近似商标监控options
        gridOptions: {
          border: true,
          resizable: true,
          showHeaderOverflow: true,
          showOverflow: true,
          highlightHoverRow: true,
          keepSource: true,
          id: 'full_edit_1',
          height: 600,
          rowId: 'id',
          toolbarConfig: {
            slots: {
              buttons: 'toolbar_buttons'
            }
          },
          filterConfig: {
            remote: true,
            iconNone: 'el-icon-search',
          },
          sortConfig: {
            trigger: 'cell',
            remote: true
          },
          columnKey: true,
          scrollX: {
            enabled: false
          },
          data: [],
          Props: {
            height: '720px',
            stripe: true,
            cellStyle: this.cellStyle
          },
          columns: [
            {
              title: '商标名称',
              field: 'trademarkName',
            },
            {
              field: 'applyPersonName',
              title: '公告商标申请人',
              slots: { default: 'applicant' },
              filters: [{data: null}],
              filterRender: { name: 'ElInput', options: [], placeholder: '请选择', props: { placeholder: '请选择'} }
            },
            {
              field: 'appno',
              title: '申请号',
              slots: { default: 'applyNumber' },
              filters: [{data: null}],
              filterRender: { name: 'ElInput', options: [], placeholder: '请选择', props: {placeholder: '请选择'} }
            },
            {
              field: 'niceCodeString',
              title: '类别',
              filters: [
                {label: '01', value: '01'},
                {label: '02', value: '02'},
                {label: '03', value: '03'},
                {label: '04', value: '04'},
                {label: '05', value: '05'},
                {label: '06', value: '06'},
                {label: '07', value: '07'},
                {label: '08', value: '08'},
                {label: '09', value: '09'},
                {label: '10', value: '10'},
                {label: '11', value: '11'},
                {label: '12', value: '12'},
                {label: '13', value: '13'},
                {label: '14', value: '14'},
                {label: '15', value: '15'},
                {label: '16', value: '16'},
                {label: '17', value: '17'},
                {label: '18', value: '18'},
                {label: '19', value: '19'},
                {label: '20', value: '20'},
                {label: '21', value: '21'},
                {label: '22', value: '22'},
                {label: '23', value: '23'},
                {label: '24', value: '24'},
                {label: '25', value: '25'},
                {label: '26', value: '26'},
                {label: '27', value: '27'},
                {label: '28', value: '28'},
                {label: '29', value: '29'},
                {label: '30', value: '30'},
                {label: '31', value: '31'},
                {label: '32', value: '32'},
                {label: '33', value: '33'},
                {label: '34', value: '34'},
                {label: '35', value: '35'},
                {label: '36', value: '36'},
                {label: '37', value: '37'},
                {label: '38', value: '38'},
                {label: '39', value: '39'},
                {label: '40', value: '40'},
                {label: '41', value: '41'},
                {label: '42', value: '42'},
                {label: '43', value: '43'},
                {label: '44', value: '44'},
                {label: '45', value: '45'}
              ],
              filterRender: {
                name: 'FilterContent',
                options: [],
                props: {multiple: true, filterable: true, placeholder: '请选择'}
              }
            },
            {
              field: 'trialNumber',
              title: '公告期',
              slots: { default: 'trialNumber' },
              filters: [{data: null}],
              filterRender: { name: 'ElInput', options: [], placeholder: '请选择', props: {placeholder: '请选择'} }
            },
            {
              field: 'objectionDate',
              title: '异议绝限',
              filters: [{data: null}],
              formatter: ({row}) => row.objectionDate,
              filterRender: {
                name: 'ElDatePicker',
                options: [],
                props: {
                  type: 'daterange',
                  rangeSeparator: '至',
                  unlinkPanels: true,
                  startPlaceholder: '开始日期',
                  endPlaceholder: '结束日期',
                  valueFormat: "yyyy-MM-dd",
                }
              }
            },
            {
              field: 'trademarkStatusDescription',
              title: '商标状态',
            },
          ],
          importConfig: {
            remote: true,
            importMethod: this.importMethod,
            types: ['xlsx'],
            modes: ['insert']
          },
          exportConfig: {
            remote: true,
            exportMethod: this.exportMethod
          },
          checkboxConfig: {
            reserve: true,
            highlight: true,
            range: true
          },
          editConfig: {
            trigger: 'click',
            mode: 'row',
            showStatus: true
          }
        },
        gridOptionss: {
          border: true,
          resizable: true,
          showHeaderOverflow: true,
          showOverflow: true,
          highlightHoverRow: true,
          keepSource: true,
          id: 'full_edit_2',
          height: 600,
          rowId: 'id',
          toolbarConfig: {
            slots: {
              buttons: 'toolbar_buttons'
            }
          },
          filterConfig: {
            remote: true,
            iconNone: 'el-icon-search',
          },
          sortConfig: {
            trigger: 'cell',
            remote: true
          },
          columnKey: true,
          scrollX: {
            enabled: false
          },
          data: [],
          Props: {
            height: '720px',
            stripe: true,
            cellStyle: this.cellStyle
          },
          columns: [
            {
              title: '商标图样',
              slots: { default: 'imageUrl' }
            },
            {
              field: 'applyPersonName',
              title: '公告商标申请人',
              slots: { default: 'applicant' },
              filters: [{data: null}],
              filterRender: { name: 'ElInput', options: [], placeholder: '请选择', props: { placeholder: '请选择'} }
            },
            {
              field: 'appno',
              title: '申请号',
              slots: { default: 'applyNumber' },
              filters: [{data: null}],
              filterRender: { name: 'ElInput', options: [], placeholder: '请选择', props: {placeholder: '请选择'} }
            },
            {
              field: 'niceCodeString',
              title: '类别',
              filters: [
                {label: '01', value: '01'},
                {label: '02', value: '02'},
                {label: '03', value: '03'},
                {label: '04', value: '04'},
                {label: '05', value: '05'},
                {label: '06', value: '06'},
                {label: '07', value: '07'},
                {label: '08', value: '08'},
                {label: '09', value: '09'},
                {label: '10', value: '10'},
                {label: '11', value: '11'},
                {label: '12', value: '12'},
                {label: '13', value: '13'},
                {label: '14', value: '14'},
                {label: '15', value: '15'},
                {label: '16', value: '16'},
                {label: '17', value: '17'},
                {label: '18', value: '18'},
                {label: '19', value: '19'},
                {label: '20', value: '20'},
                {label: '21', value: '21'},
                {label: '22', value: '22'},
                {label: '23', value: '23'},
                {label: '24', value: '24'},
                {label: '25', value: '25'},
                {label: '26', value: '26'},
                {label: '27', value: '27'},
                {label: '28', value: '28'},
                {label: '29', value: '29'},
                {label: '30', value: '30'},
                {label: '31', value: '31'},
                {label: '32', value: '32'},
                {label: '33', value: '33'},
                {label: '34', value: '34'},
                {label: '35', value: '35'},
                {label: '36', value: '36'},
                {label: '37', value: '37'},
                {label: '38', value: '38'},
                {label: '39', value: '39'},
                {label: '40', value: '40'},
                {label: '41', value: '41'},
                {label: '42', value: '42'},
                {label: '43', value: '43'},
                {label: '44', value: '44'},
                {label: '45', value: '45'}
              ],
              filterRender: {
                name: 'FilterContent',
                options: [],
                props: {multiple: true, filterable: true, placeholder: '请选择'}
              }
            },
            {
              field: 'trialNumber',
              title: '公告期',
              slots: { default: 'trialNumber' },
              filters: [{data: null}],
              filterRender: { name: 'ElInput', options: [], placeholder: '请选择', props: {placeholder: '请选择'} }
            },
            {
              field: 'objectionDate',
              title: '异议绝限',
              filters: [{data: null}],
              formatter: ({row}) => row.objectionDate,
              filterRender: {
                name: 'ElDatePicker',
                options: [],
                props: {
                  type: 'daterange',
                  rangeSeparator: '至',
                  unlinkPanels: true,
                  startPlaceholder: '开始日期',
                  endPlaceholder: '结束日期',
                  valueFormat: "yyyy-MM-dd",
                }
              }
            },
            {
              field: 'trademarkStatusDescription',
              title: '商标状态',
            },
          ],
          importConfig: {
            remote: true,
            importMethod: this.importMethod,
            types: ['xlsx'],
            modes: ['insert']
          },
          exportConfig: {
            remote: true,
            exportMethod: this.exportMethod
          },
          checkboxConfig: {
            reserve: true,
            highlight: true,
            range: true
          },
          editConfig: {
            trigger: 'click',
            mode: 'row',
            showStatus: true
          }
        },
        //竞争对手监控options
        gridOptions2: {
          border: true,
          resizable: true,
          showHeaderOverflow: true,
          showOverflow: true,
          highlightHoverRow: true,
          keepSource: true,
          id: 'full_edit_3',
          height: 600,
          rowId: 'id',
          toolbarConfig: {
            slots: {
              buttons: 'toolbar_buttons'
            }
          },
          filterConfig: {
            remote: true,
            iconNone: 'el-icon-search',
          },
          sortConfig: {
            trigger: 'cell',
            remote: true
          },
          columnKey: true,
          scrollX: {
            enabled: false
          },
          data: [],
          Props: {
            height: '720px',
            stripe: true,
            cellStyle: this.cellStyle
          },
          columns: [
            {
              title: '商标名称',
              field: 'trademarkName',
            },
            {
              title: '商标图案',
              slots: { default: 'imageUrl' }
            },
            {
              field: 'applyPersonName',
              title: '申请人名称(中文)',
              slots: { default: 'applicant' },
              filters: [{data: null}],
              filterRender: { name: 'ElInput', options: [], placeholder: '请选择', props: { placeholder: '请选择'} }
            },
            {
              title: '申请人名称(英文)',
              field: 'applyPersonNameEn',
            },
            {
              field: 'appno',
              title: '申请号',
              slots: { default: 'applyNumber' },
              filters: [{data: null}],
              filterRender: { name: 'ElInput', options: [], placeholder: '请选择', props: {placeholder: '请选择'} }
            },
            {
              field: 'niceCodeString',
              title: '类别',
              filters: [
                {label: '01', value: '01'},
                {label: '02', value: '02'},
                {label: '03', value: '03'},
                {label: '04', value: '04'},
                {label: '05', value: '05'},
                {label: '06', value: '06'},
                {label: '07', value: '07'},
                {label: '08', value: '08'},
                {label: '09', value: '09'},
                {label: '10', value: '10'},
                {label: '11', value: '11'},
                {label: '12', value: '12'},
                {label: '13', value: '13'},
                {label: '14', value: '14'},
                {label: '15', value: '15'},
                {label: '16', value: '16'},
                {label: '17', value: '17'},
                {label: '18', value: '18'},
                {label: '19', value: '19'},
                {label: '20', value: '20'},
                {label: '21', value: '21'},
                {label: '22', value: '22'},
                {label: '23', value: '23'},
                {label: '24', value: '24'},
                {label: '25', value: '25'},
                {label: '26', value: '26'},
                {label: '27', value: '27'},
                {label: '28', value: '28'},
                {label: '29', value: '29'},
                {label: '30', value: '30'},
                {label: '31', value: '31'},
                {label: '32', value: '32'},
                {label: '33', value: '33'},
                {label: '34', value: '34'},
                {label: '35', value: '35'},
                {label: '36', value: '36'},
                {label: '37', value: '37'},
                {label: '38', value: '38'},
                {label: '39', value: '39'},
                {label: '40', value: '40'},
                {label: '41', value: '41'},
                {label: '42', value: '42'},
                {label: '43', value: '43'},
                {label: '44', value: '44'},
                {label: '45', value: '45'}
              ],
              filterRender: {
                name: 'FilterContent',
                options: [],
                props: {multiple: true, filterable: true, placeholder: '请选择'}
              }
            },
            {
              field: 'flowDate',
              title: '状态更新日期',
            },
            {
              field: 'objectionDate',
              title: '异议绝限',
              filters: [{data: null}],
              formatter: ({row}) => row.objectionDate,
              filterRender: {
                name: 'ElDatePicker',
                options: [],
                props: {
                  type: 'daterange',
                  rangeSeparator: '至',
                  unlinkPanels: true,
                  startPlaceholder: '开始日期',
                  endPlaceholder: '结束日期',
                  valueFormat: "yyyy-MM-dd",
                }
              }
            },
            {
              title: '商标状态',
              slots: { default: 'trademarkStatus' }
            },
          ],
          importConfig: {
            remote: true,
            importMethod: this.importMethod,
            types: ['xlsx'],
            modes: ['insert']
          },
          exportConfig: {
            remote: true,
            exportMethod: this.exportMethod
          },
          checkboxConfig: {
            reserve: true,
            highlight: true,
            range: true
          },
          editConfig: {
            trigger: 'click',
            mode: 'row',
            showStatus: true
          }
        },
        //监控id
        monitorId:'',
        monitorApplicant:null,
        //分页信息
        current: 1,
        size: 50,
        total: 1,
        //各个国家监控结果数量(原始数据)
        countryCount: [],
        trademarkName:"",
        trademarkImageUrl:"",
        applyPersonName:"",
        //原始数据格式化后的数据
        formData: {
          //当前国家
          informantType: "",
          //监控类型 1-近似监控 2-竞争对手监控
          monitorType:"",
          //各个国家数据
          options:{},
        },
        //监控类型 0-文字 1-图像 2-竞争对手(申请人)
        monitorCategory: 2,
        //表格过滤
        niceCodes:[],
        appNo:"",
        objectionStartDate:"",
        objectionEndDate:"",
        trialNums:"",
        tmapplicant:"",
        trademarkStatusList:[],
      }
    },
    mounted() {
      this.monitorId = this.$route.query.id
      this.queryMonitoringCountryCount()

      echarts.init(document.getElementById("myCategoryCharts"))
    },
    methods: {
      //跳转
      skip(item){
        if (item == null){
          this.$router.push("?"+window.location.hash.split("?")[1])
        }
        else {
          this.$router.push(item)
        }
      },
      goback(){ window.history.go(-1) },
      //每个国家监控结果数量
      queryMonitoringCountryCount() {
        this.$axios.get(`/trademark/monitoring/getMonitoringDetailSummaryList?monitoringId=` + this.$route.query.id)
          .then(({data}) =>{
            if(data.code == 0){
              this.countryCount = data.data
              this.selectCountryListFromDB()
            }
          })
      },
      //调整第一列样式
      cellClassName({row, column, $columnIndex}) {
        if ($columnIndex == 0 && this.monitorCategory == 1) {
          return 'col-orange'
        }
      },
      //过期数据置灰
      rowStyle({row, rowIndex, $rowIndex}) {
        if (row.historyFlag){
          return 'background: #eeeeee'
        }
      },
      //案件导出
      exportManyCountryMonitoringDetail(){
        let monitoringIdList = []
        monitoringIdList.push(Number(this.monitorId))

        let countryList = []
        this.countryCount.map(item=>{
          countryList.push(item.trademarkCountry)
        })

        let now = new Date()
        let year = now.getFullYear()
        let mouth = now.getMonth() < 10 ? '0'+now.getMonth() : now.getMonth()
        let day = now.getDate() < 10 ? '0'+now.getDate() : now.getDate()

        this.$axios.post("/trademark/monitoring/exportManyCountryMonitoringDetail",
          {
            "monitoringIdSet":monitoringIdList,
            "trademarkCountrySet":[this.formData.informantType],
            "monitorType":this.formData.monitorType,

            "niceCodeSet": this.niceCodes == "" ? null : this.niceCodes.split(","),
            "applyNumber": this.appNo == "" ? null : this.appNo,
            "objectionStartDate": this.objectionStartDate == "" ? null : this.objectionStartDate,
            "objectionEndDate": this.objectionEndDate == "" ? null : this.objectionEndDate,
            "trialNumber": this.trialNums == "" ? null : this.trialNums,
            "applyPersonName": this.tmapplicant == "" ? null : this.tmapplicant,
          },
          {responseType:'blob'})
          .then((res) => { // 处理返回的文件流
            const content = res.data;
            const blob = new Blob([content], {type:'application/octet-stream;charset=utf-8'});
            let fileName = res.headers["content-disposition"].split("filename=")[1]
            fileName = decodeURI(fileName)
            if ('download' in document.createElement('a')) { // 非IE下载
              const elink = document.createElement('a');
              elink.download = fileName ? fileName : '监控结果-' + year+mouth+day + '.xlsx'
              elink.style.display = 'none';
              elink.href = URL.createObjectURL(blob);
              document.body.appendChild(elink);
              elink.click();
              URL.revokeObjectURL(elink.href); // 释放URL 对象
              document.body.removeChild(elink)
            } else { // IE10+下载
              navigator.msSaveBlob(blob, fileName)
            }
          })
          .catch((error) => {
            console.log(error);
          })
      },
      //获取所有国家信息
      selectCountryListFromDB() {
        this.$axios.get("/trademark/monitoringcountry/queryAllCountry")
          .then(({data}) =>{
            if(data.code == 0){
              let hk_can_handle = localStorage.getItem('selectedcountry')
              data = data.data.filter(country=>hk_can_handle.includes(country.counKey))
              this.formData.informantType = data[0].counKey
              this.formData.options = data
              this.formData.options.map(item => {
                this.countryCount.map(items => {
                  if(item.counKey == items.trademarkCountry){
                    item.trademarkCount = items.trademarkCount
                    this.formData.monitorType = items.monitorType
                    this.monitorCategory = items.monitorCategory
                  }
                  return items
                })
                return item
              })
              this.resultList();

              this.trademarkName = this.countryCount[0].trademarkName
              this.trademarkImageUrl = this.countryCount[0].trademarkImageUrl
              this.applyPersonName = this.countryCount[0].applyPersonName
            }
          })
      },
      //获取商标图像地址
      queryDataUrl(data) {
        if (data && data.startsWith('/group')) return "https://tmimage.lanternfish.cn" + data
        else return data
      },
      //请求当前选中国家下的监控结果
      resultList() {
        let trademarkCountrySet = []
        trademarkCountrySet.push(this.formData.informantType)
        let monitoringIdSet = []
        monitoringIdSet.push(this.monitorId)

        let param = {
          //必填项
          "monitoringIdSet": monitoringIdSet,
          "trademarkCountrySet": trademarkCountrySet,
          "monitorType": this.formData.monitorType,
          //当前页面信息
          "current": this.current,
          "size": this.size,
          //二次筛选
          "niceCodeSet": this.niceCodes == "" ? null : this.niceCodes.split(","),
          "applyNumber": this.appNo == "" ? null : this.appNo,
          "objectionStartDate": this.objectionStartDate == "" ? null : this.objectionStartDate,
          "objectionEndDate": this.objectionEndDate == "" ? null : this.objectionEndDate,
          "trialNumber": this.trialNums == "" ? null : this.trialNums,
          "applyPersonName": this.tmapplicant == "" ? null : this.tmapplicant,
        }

        this.$axios.post("/trademark/monitoring/queryManyCountryMonitoringDetailPage", param)
          .then(({data}) => {
            if (data.data.records.length) {
              this.gridOptions.data = data.data.records
              this.gridOptionss.data = data.data.records
              this.gridOptions2.data = data.data.records
              this.total = data.data.total
              this.size = data.data.size
            }
            else {
              this.gridOptions.data = []
              this.gridOptionss.data = []
              this.gridOptions2.data = []
              this.total = 0
            }
          })
          .catch(error => {
            console.log(error)
          })

        let graphData = {
          competitorApplyNumberList:[],
          competitorNiceCodeList:[]
        }

        this.countryCount.map(item=>{
          if (item.trademarkCountry == this.formData.informantType){
            graphData.competitorApplyNumberList = item.competitorApplyNumberList
            graphData.competitorNiceCodeList = item.competitorNiceCodeList
          }
        })

        //初始化申请人监控顶部
        if (this.monitorCategory == 2){
          this.initApplicantMonitorTop(graphData)
        }
      },
      //初始化统计图 包括中间的本周商标状态展示 和 右侧的申请人商标
      initApplicantMonitorTop(graphData){
        //graphData 包含所有统计信息 仅在选择的监控国家为“中国大陆”时显示中间的商标状态统计
        let myCategoryCharts = require('echarts').getInstanceByDom(document.getElementById("myCategoryCharts"))
        let myCategoryChartsOptions = this.getMyCategoryChartsOptions(graphData.competitorNiceCodeList)
        myCategoryCharts.setOption(myCategoryChartsOptions)

        graphData.competitorApplyNumberList.forEach(item=>{
          this.trademarkStatusList.push(item)
        })
        this.trademarkStatusList.push({
          flowName:"",
          flowCount:""
        })
      },
      getMyCategoryChartsOptions(niceCodeList){
        let xAxis = []
        let oldNiceCodeCount = []

        let maxCount = 0
        if (niceCodeList != null){
          niceCodeList.map(item=>{
            xAxis.push(this.getNiceCodeName(item.niceCode))
            let count = item.oldNiceCodeCount + item.newNiceCodeCount
            oldNiceCodeCount.push(count)
            maxCount = count > maxCount ? count : maxCount
          })
        }
        let myMaxCount = (( maxCount / 100 ) + 1)*100

        return {
          color:['#0052D9'],
          grid:{
            top:30,
            bottom:50,
            left:40,
            right:5
          },
          tooltip:{
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
              shadowStyle: {
                color: '#181818',
                opacity: 0.05,
              },
            },
          },
          xAxis: {
            type:'category',
            data:xAxis,
            axisLabel:{
              interval:0,
              formatter:function (param){
                return param.substring(0, 3)
              }
            }
          },
          yAxis: {
            type:'value',
            minInterval:1,
            // max: maxCount <= 10 ? 10 : maxCount <= 100 ? 100 : myMaxCount
          },
          series: {
            type: 'bar',
            stack: 'category',
            data: oldNiceCodeCount,
            barWidth:'30%',
            label:{
              show:true,
              position:'top',
            }
          },
          dataZoom:{
            type:"inside",
            show:true,
            startValue:0,
            endValue:6
          }
        }
      },
      getNiceCodeName(niceCode){
        let title = ""
        switch (Number(niceCode)){
          case 1:
            title="01类 工业用化学品"
            break
          case 2:
            title="02类 颜料油漆树脂"
            break
          case 3:
            title="03类 清洁化妆用品"
            break
          case 4:
            title="04类 油脂燃料能源"
            break
          case 5:
            title="05类 医药卫生用品"
            break
          case 6:
            title="06类 金属五金器具"
            break
          case 7:
            title="07类 机器机械设备"
            break
          case 8:
            title="08类 餐具手工器具"
            break
          case 9:
            title="09类 科学电子设备"
            break
          case 10:
            title="10类 医疗护理器械"
            break
          case 11:
            title="11类 照明空调卫浴"
            break
          case 12:
            title="12类 交通运输工具"
            break
          case 13:
            title="13类 军火烟火爆竹"
            break
          case 14:
            title="14类 珠宝首饰钟表"
            break
          case 15:
            title="15类 乐器及其配件"
            break
          case 16:
            title="16类 办公教学用品"
            break
          case 17:
            title="17类 塑料橡胶制品"
            break
          case 18:
            title="18类 箱包皮具皮革"
            break
          case 19:
            title="19类 非金属的建材"
            break
          case 20:
            title="20类 家具容器骨石"
            break
          case 21:
            title="21类 家居厨房器具"
            break
          case 22:
            title="22类 绳网帐篷帆布"
            break
          case 23:
            title="23类 纺织用纱和线"
            break
          case 24:
            title="24类 日常用纺织品"
            break
          case 25:
            title="25类 服装服饰鞋帽"
            break
          case 26:
            title="26类 花边配饰假发"
            break
          case 27:
            title="27类 地毯席类墙纸"
            break
          case 28:
            title="28类 游戏运动器具"
            break
          case 29:
            title="29类 蔬果肉蛋奶油"
            break
          case 30:
            title="30类 咖啡面点调料"
            break
          case 31:
            title="31类 水果蔬菜花卉"
            break
          case 32:
            title="32类 啤酒饮料糖浆"
            break
          case 33:
            title="33类 含酒精的饮料"
            break
          case 34:
            title="34类 烟草烟具火柴"
            break
          case 35:
            title="35类 广告办公销售"
            break
          case 36:
            title="36类 保险金融服务"
            break
          case 37:
            title="37类 建筑修理安装"
            break
          case 38:
            title="38类 电信通信服务"
            break
          case 39:
            title="39类 运输仓储服务"
            break
          case 40:
            title="40类 材料处理服务"
            break
          case 41:
            title="41类 教育娱乐文体"
            break
          case 42:
            title="42类 设计咨询开发"
            break
          case 43:
            title="43类 饮食住宿看护"
            break
          case 44:
            title="44类 医疗美容园林"
            break
          case 45:
            title="45类 法律安全服务"
            break
        }
        return title
      },
      //当前页数变化触发
      handleCurrentChange({currentPage, pageSize}) {
        this.current = currentPage;
        this.resultList();
      },
      //过滤变化触发
      filterChange(){
        let getCheckedFilters = this.monitorCategory != 2 ? this.$refs.xGrid.getCheckedFilters() : this.$refs.xGrid2.getCheckedFilters()

        this.niceCodes = ""
        this.appNo = ""
        this.objectionStartDate = ""
        this.objectionEndDate = ""
        this.trialNums = ""
        this.tmapplicant = ""

        getCheckedFilters.map(item =>{
          switch (item.property) {
            // 类别
            case "niceCodeString":
              this.niceCodes = item.values.join(',').trim()
              break
            // 申请号
            case "appno":
              this.appNo = item.datas.join(',').trim()
              break
            // 异议期限
            case "objectionDate":
              this.objectionStartDate = item.datas[0][0]
              this.objectionEndDate = item.datas[0][1]
              break
            // 公告期号
            case "trialNumber":
              this.trialNums = item.datas.join(',').trim()
              break
            // 申请人名称
            case "applyPersonName":
              this.tmapplicant = item.datas.join(',').trim()
              break
            //trialNumber
          }
        })
        //获取过滤结果
        this.resultList()
      },
      //关联查询
      guanlian(value,index){
        if(index == 0){
          let routeData = this.$router.resolve({ path: '/query/announcementDetail',query: {
              searchText: value.trialNumber,
              value: value.applyNumber
            }});
          window.open(routeData.href, '_blank');
          return;
        }
        let routeData = this.$router.resolve({ path: '/PQ',query: {
            value: index,
            searchText: value,
            country: this.formData.informantType
          }});
        window.open(routeData.href, '_blank');
      },
    },
  }
</script>


<style lang="less">
  .trademarkMonitoring_result{
    /deep/.vxe-table--body-wrapper::-webkit-scrollbar {
      width: 10px; // 横向滚动条
      height: 10px; // 纵向滚动条 必写
    }
    // 滚动条的滑块
    /deep/.vxe-table--body-wrapper::-webkit-scrollbar-thumb {
      background: hsla(0, 0%, 53%, .1);
      border-radius: 10px
    }
    /deep/.vxe-table--body-wrapper::-webkit-scrollbar-track {
      background: hsla(0, 0%, 53%, .1)
    }

    .sortable-column-demo .vxe-header--row .vxe-header--column.sortable-ghost,
    .sortable-column-demo .vxe-header--row .vxe-header--column.sortable-chosen {
      background-color: #dfecfb;
    }

    .sortable-column-demo .vxe-header--row .vxe-header--column.col--fixed {
      cursor: no-drop;
    }

    .vxe-body--row {
      transition: all 0.2s ease-in;
    }

    .vxe-table--header {
      border-radius: 15px 15px 0px 0px;
      overflow: hidden;
    }


    .vxe_grid_header {
      border-radius: 15px 15px 0px 0px!important;
      overflow: hidden!important;
      background: #E2EFFF!important;
    }

    .row--current, .row--hover {
      background: #ffffff !important;
      -webkit-box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
      box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
      -webkit-transform: translate3d(0, 0px, -2px);
      transform: translate3d(0, 1px, -2px);
    }

    .vxe-body--row {
      transition: all 0.2s ease-in;
    }
    .col-orange div {
      //width: 118px !important;
      //display: flex !important;
      //height: 48px !important;
      //padding: 2px 0 !important;
      //justify-content: center !important;
      display: inline-block;
      line-height: 1;
    }
  }

</style>

<style scoped lang="less">
  .trademarkMonitoring_result {
    .danTitle {
      height: 50px;
      line-height: 50px;
      font-size: 14px;
      color: #999999;
      font-weight: 600;
      position: relative;
      margin-left: 34px;
      &:before {
        position: absolute;
        content: '';
        width: 3px;
        height: 16px;
        top: 17px;
        background: #1a3dbf;
      }
      span{
        margin: 0 9px;
        &:nth-of-type(1){
          margin-left: 18px;
        }
        &:nth-of-type(4){
          color: #506bce;
        }
      }
      span:hover{
        cursor: pointer;
        color: #506bce;
      }
    }
    .monitoring_top {
      width: 98%;
      border: 1px solid #e4e4e4;
      background: #fff;
      margin: 0 auto 15px;
      position: relative;

      .condition {
        padding: 30px 25px;
        .el-input {
          width: 430px;
          margin-right: 20px;
          padding: 0px;
          margin-left: 10px;

          .el-input__inner {
            height: 36px;
          }
        }

        .el-input .el-input__inner {
          height: 36px;
        }

        .left {
          float: left;
          margin-bottom: 30px;
          img {
            width: 149px;
          }
        }

        .right {
          width: 87%;
          h2 {
            font-size: 14px;
            color: #222222;
            text-indent: 5px;
            border-left: #1a3dbf solid 4px;
            margin-bottom: 20px;
          }

          .selectCountry {
            margin-top: 30px;
            display: flex;
            align-items: baseline;
            /deep/.el-radio{
              margin-bottom: 10px;
            }
          }

          span:nth-of-type(1) {
            display: inline-block;
            margin-top: 8px;

            .dition {
              width: 100%;
              margin-top: 5px;

              div:nth-of-type(1) span:nth-of-type(2) {
                margin-left: 82px;
                text-decoration: underline;
                color: #1c7dea;
                margin-right: 30px;
                cursor: pointer;
              }

              .category {
                display: inline-block;
                min-width: 92px;
                min-height: 30px;
                border: 1px solid #dadada;
                text-align: center;
                line-height: 30px;
                margin-right: 20px;
                cursor: pointer;
                margin-top: 5px;
                padding: 0 8px
              }

              .noQuery {
                border: 1px solid transparent;
                color: #aaaaaa;
              }

              .category:hover {
                background-color: #fcf1f1;
                border: solid 1px #d01027;
                color: #d01027;
              }

              .noQuery:hover {
                background-color: #fcf1f1;
                border: 1px solid transparent;
                color: #000;
              }
            }
          }
        }
      }
      .condition1{
        .condition1Item{
          margin: 20px;
          height: 270px;

          h3{
            position: relative;
            margin-left: 20px;
            font-weight: 500;
            font-size: 16px;
            color: #000000;
            line-height: 19px;
            text-align: left;
            font-style: normal;
            text-transform: none;
          }
          h3:before{
            content: '';
            position: absolute;
            top: 2px;
            left: -10px;
            height: 16px;
            width: 4px;
            background-color: #1A3DBF;
          }

          .monitorResultItem{
            max-width: 370px;
            margin: 10px;
            padding: 10px;
            background: #F4F8FE;
            border-radius: 5px 5px 5px 5px;
            border: 1px dashed #1A3DBF;
          }

          .monitorTrademarkStatusList{
            margin: 20px 20px 0 20px;
            max-height: 250px;
            overflow: auto;
            text-align: center;
            scrollbar-width:thin;

            .monitorTrademarkStatusItem{
              text-align: left;
              display: inline-block;
              width: 150px;
              font-size: 14px;
              padding: 10px;
              margin: 0 20px 15px 20px;
              background: #F6FEF4;
              border-radius: 5px 5px 5px 5px;
              border: 1px dashed #5F9851;
            }
            .monitorTrademarkStatusItem:last-of-type{
              border: 1px dashed transparent;
              background: transparent;
            }
          }
        }
        .leftLine{
          position: relative;
        }
        .leftLine:before{
          content: '';
          position: absolute;
          top: 80px;
          left: -20px;
          height: 70px;
          width: 1px;
          background-color: #8BAEF2;
        }
        .rightLine{
          position: relative;
        }
        .rightLine:before{
          content: '';
          position: absolute;
          top: 80px;
          right: -20px;
          height: 70px;
          width: 1px;
          background-color: #8BAEF2;
        }
      }

      .graphBar{
        position: absolute;
        top: 20px;
        right: 20px;
        height: 300px;
        width: 430px;
      }
      .graphTree{
        position: absolute;
        top: 10px;
        right: 470px;
        height: 320px;
        width: 500px;
        /deep/canvas{
          display: none;
          &:nth-of-type(1){
            display: block;
          }
        }

      }
    }

    .monitoring_two {
      width: 98%;
      margin: 15px auto;

      .add {
        width: 140px;
        height: 34px;
        background: #d01027;
        border-radius: 3px;
        text-align: center;
        line-height: 34px;
        color: #fff;
        cursor: pointer;
      }

      .add:hover {
        background: #d73e3f;
      }
    }

    .toQuery{
      color: rgb(100, 131, 237);
      cursor: pointer;
    }

    .monitoring_table {
      padding: 10px;
      width: 98%;
      box-sizing: border-box;
      margin: 0 auto;
      background: white;
      border: 1px solid #ddddde;
    }

    .primary {
      background: #d01027;
      border: #d01027;
    }

    .primary:hover {
      background: #d73e3f;
    }

    .can:hover {
      color: white;
      background-color: #d01027;
      border: 1px solid #d01027;
    }

    .fyBlock {
      padding-bottom: 1%;
      padding-top: 2%;
      text-align: center;
    }

    .tanchu {
      margin-bottom: 15px;

      span {
        display: inline-block;
        margin-right: 35px;
      }

      .el-input {
        width: 380px;

        .el-input__inner {
          height: 36px;
        }
      }

      .code {
        width: 97px;
        height: 40px;
        margin-left: 14px;
        position: absolute;
        cursor: pointer;
      }

      .warning {
        height: 36px;
        width: 700px;
        background: #fcd7d7;
        line-height: 36px;
        text-indent: 20px;
        color: #eb4142;
        position: absolute;
        top: 0px;
        left: 114px;
        border-radius: 5px;
        font-size: 14px;
        letter-spacing: 1px;

        i {
          margin-right: 4px;
        }
      }
    }
  }

  @media screen and (max-width: 1903px) {
    .trademarkMonitoring_result .monitoring_top .condition .right {
      width: 85% !important;
    }

    .trademarkMonitoring_result .monitoring_top .condition .right span:nth-of-type(1) .dition div:nth-of-type(2) {
      width: 680px !important;
    }
  }
</style>
